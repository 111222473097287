.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.aspect-ratio-9-7 {
  width: 100%;
  /* Setting the height using padding-top to maintain the 9:16 aspect ratio */
  padding-top: calc(100% * 7 / 9);
  position: relative;
  overflow: hidden;
  background-color: lightgray;
  /* Optional: for visualization */
}

.aspect-ratio-9-7 > div {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  /* Ensure the video fills the height and is centered */
  object-fit: cover;
  width: auto;
  min-width: calc(100% * 9 / 6.5);
}

@keyframes blurIn {
  from {
    backdrop-filter: blur(0);
  }

  to {
    backdrop-filter: blur(4px);
    /* Adjust the value as needed */
  }
}

.animated-backdrop-blur {
  animation: blurIn 0.2s ease-in-out forwards;
}
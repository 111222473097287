@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
  color: #242424;
}

.max-w-872 {
  max-width: 872px;
}

.min-h-70vh {
  min-height: 70vh;
}
.min-h-80vh {
  min-height: 80vh;
}


.bg-green {
  background: #92EE7B;
}
.bg-purple {
  background: #7F43FF;
}

.color-purple {
  color: #7F43FF;
}

.border-purple{
  border: 1px solid #7F43FF;
}

.mantine-Slider-track{
  background-color: rgb(225 211 255 / 38%);
  border-radius: 10px;
}
.mantine-Slider-bar{
  border: 4px solid #7F43FF;
  border-radius: 10px;
  background-color: #7F43FF;
}
.mantine-Slider-thumb{
  width: 16px;
  border: 3px solid #7F43FF;
  border-radius: 10px;
}

iframe{ 
  margin: auto;
}